<template>
  <div class="menus">
    <div id="">
      <el-form
        ref="queryForm"
        :inline="true"
        v-hasPermi="['study:sdy:list']"
        label-width="80px"
        style="width: 100%; text-align: left"
      >
        <el-form-item label="用户名称" prop="roleName">
          <el-input
            v-model="queryParams.realname"
            placeholder="请输入用户名称"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="标题" prop="roleKey">
          <el-input
            v-model="queryParams.articleName"
            placeholder="请输入标题"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="选择学校" prop="bumencid">
          <treeselect
            v-model="queryParams.bumencid"
            class="treeselect-main"
            :options="nstitution"
            :normalizer="normalizer"
            placeholder="选择学校"
            :disable-branch-nodes="true"
            loadingText
            noOptionsText="无数据"
            :show-count="true"
          />
        </el-form-item>
        <el-form-item label="起止时间" prop="status" label-width="100px">
          <el-date-picker
            v-model="dateRange"
            size="small"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="status">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            v-hasPermi="['study:sdy:list']"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            v-hasPermi="['study:sdy:list']"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
    >
      <el-table-column prop="id" label="编号" width="80"> </el-table-column>
      <el-table-column prop="realname" label="用户名称"> </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column
        prop="bumenName"
        label="部门"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="timenum" label="学习时长">
        <template slot-scope="scope">
          {{ formatDuring(scope.row.timenum) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="credit"
        label="奖励积分"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['study:sdy:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="info()"
    />
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination";
import request from "../../../utils/request.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      total: 0,
      formLabelWidth: "120px",
      tableData: [],
      id: 0,
      dateRange: [],
      queryParams: {
        realname: "", //搜索名称
        articleName: "", //搜索电话
        bumencid: "",
        createTime: "",
        endTime: "",
        pageSize: 10, //分页默认一页显示15条数据
        total: 0, //共多少条数据从接口获取
        pageNum: 1, //分页默认显示第1页数据
      },
      clickMsg: "1", //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
      nstitution: [],
    };
  },
  created() {
    this.info();
    // this.getList();
    this.school();
  },
  activated() {
    this.info();
  },
  computed: {
    formatDuring() {
      return function (val) {
        // var day = Math.floor( val/ (24*3600) ); // Math.floor()向下取整
        const hour = Math.floor(val / 3600);
        const minute = Math.floor((val - hour * 3600) / 60);
        const second = val - hour * 3600 - minute * 60;
        if (hour > 0) {
          return hour + "时" + minute + "分" + second + "秒";
        }
        if (minute > 0) {
          return minute + "分" + second + "秒";
        }
        return second + "秒";
      };
    },
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        children: node.children,
      };
    },
    //获取列表数据
    info() {
      this.clickMsg = "1"; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页

      let dats = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        realname: this.queryParams.realname,
        articleName: this.queryParams.articleName,
        bumencid: this.queryParams.bumencid,
        createTime: this.queryParams.createTime,
        endTime: this.queryParams.endTime,
      };
      return request({
        url: "userArticleStudy/queryUserArticleStudy",
        method: "get",
        params: dats,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.data;
          // this.queryParams.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
          this.total = res.data.pageBean.count; //接口获取到的共多少条
        }
      });
    },
    school() {
      this.queryParams.bumencid = undefined;
      return request({
        url: "bumen/queryBumen",
        method: "get",
      }).then((res) => {
        if (res.status == 200) {
          if (sessionStorage.getItem("schoolIdList") == "undefined") {
            this.nstitution = this.handleTree(res.data, "id", "parentid");
          } else {
            let arr = sessionStorage.getItem("schoolIdList").split(",");
            let schoolArr = [];
            arr.forEach((item) => {
              res.data.forEach((school) => {
                if (school.id == item) schoolArr.push(school);
              });
            });
            this.nstitution = this.handleTree(schoolArr, "id", "parentid");
          }
          // this.nstitution = this.handleTree(res.data,"id",'parentid')
          // console.log(this.nstitution)
        }
      });
    },
    //添加
    addition() {
      this.$router.push({ path: "/Home/newly" });
    },

    //搜索
    handleQuery() {
      if (this.dateRange.length !== 0) {
        this.queryParams.createTime = this.dateRange[0];
        this.queryParams.endTime = this.dateRange[1];
      }
      this.queryParams.pageNum = 1;
      this.info();
    },
    //搜索重置
    resetQuery() {
      this.dateRange = [];
      this.queryParams.realname = "";
      this.queryParams.articleName = "";
      this.queryParams.bumencid = undefined;
      this.queryParams.createTime = "";
      this.queryParams.endTime = "";
      this.info();
    },

    //删除
    handleDelete(index, row) {
      // console.log( row);
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          return request({
            url: "userArticleStudy/deleteUserArticleStudyById",
            method: "delete",
            data: data,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.info();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}
.treeselect-main {
  width: 280px;
  margin-top: 5px;
  line-height: 20px;
}
.vue-treeselect >>> .vue-treeselect__placeholder {
  line-height: 30px;
}
.vue-treeselect >>> .vue-treeselect__control {
  height: 30px !important;
}
.vue-treeselect >>> .vue-treeselect__menu {
  overflow-x: auto !important;
}
.vue-treeselect >>> .vue-treeselect__label {
  overflow: unset;
  text-overflow: unset;
}
.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
 
